import './App.css';
import React, { useState, useEffect } from 'react';

function App() {
  return (
    <>
      <Header />
      <Title />
      <h2 className="launch-message">Launching in</h2>
      <CountdownTimer />
      <Footer />
    </>
  );
}

const Header = () =>{
  return (
    <div className="header">
      <img src="./logo-no-background.png" alt="logo" height="80%" className="image"></img>
    </div>
  );
}

const CountdownTimer = () =>{
  const targetDate = new Date('2024-05-01T00:00:00').getTime();

  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() =>{
    const countdownInterval = setInterval(()=>{
      const currentDate = new Date().getTime();

      const remainingTime = targetDate - currentDate;

      const updatedDays = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
      const updatedHours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const updatedMinutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
      const updatedSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

      setDays(updatedDays);
      setHours(updatedHours);
      setMinutes(updatedMinutes);
      setSeconds(updatedSeconds);

      if(remainingTime <= 0){
        clearInterval(countdownInterval);
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [targetDate]);

  return (
    <div className="countdown-timer">
      <div className="time-container">
        <div className="time">{days}</div>
        <div className="time-description">Days</div>
      </div>
      <div className="time-container">:</div>
      <div className="time-container">
        <div className="time">{hours}</div>
        <div className="time-description">Hours</div>
        </div>
      <div className="time-container">:</div>
      <div className="time-container">
        <div className="time">{minutes}</div>
        <div className="time-description">Minutes</div>
        </div>
      <div className="time-container">:</div>
      <div className="time-container">
        <div className="time">{seconds}</div>
        <div className="time-description">Seconds</div>
        </div>
    </div>
  )
}

const Title = () =>{
  return (
    <div>
      <h1 className="website-title">InfoMed-<span className="x">X</span></h1>
    </div>
  );
}

const Footer = () =>{
  return (
    <footer className="footer">

    </footer>
  );
}


export default App;
